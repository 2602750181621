<template>
  <div class="advertismentWrapper offerManagement listingManagementWrapper">
     <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="showSpinner"/>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-row class="filterRow">
            <b-col md="12" sm="12" class="mb-1">
              <h3 class="card-title">Filters</h3>
            </b-col>

            <b-col md="2" sm="4" class="mb-0">
              <b-form-group label="Location">
                <b-form-input v-model="location" placeholder="Search" />
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" sm="4" class="mb-0">
              <b-form-group label="Price Range">
                <b-form-select
                  :options="priceRangeOptions"
                  v-model="priceRange"
                  placeholder="Search"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="2" sm="4" class="mb-0">
              <b-form-group label="Beds">
                <b-form-select
                  :options="bedOptions"
                  v-model="bed"
                  placeholder="Search"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="4" class="mb-0">
              <b-form-group label="Status">
                <b-form-select
                  :options="filteredStatuses.filter((e)=>{return e !== 'pending'})"
                  v-model="status"
                  placeholder="Search"
                  style="text-transform:capitalize;"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="2" style="margin-top: 17px">
              <span
                @click="
                  (status = 'Select'), (priceRange = 'Select'), (bed = 'Any'),(location = '')
                "
                style="
                  cursor: pointer !important;
                  text-decoration: underline;
                  color: #17D1C6;
                  font-weight: 500;
                  margin-top: 10px;
                "
              >
                Clear Filter</span
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" v-if="!showSpinner">
        <b-card class="tableCardBody">
          <b-row>
            <b-col md="9" sm="7" class="my-1">
              <label
                style="
                  font-style: normal;
                  font-weight: 400;
                  font-size: 24px;
                  line-height: 29px;
                  color: #2c2c2c;
                "
                >Property List</label
              >
            </b-col>
            <b-col md="3" sm="5" class="my-1 myOfferSerachInput" v-if="filteredItems && filteredItems.length" >
              <b-form-input placeholder="Search..." v-model="filter" autocomplete="off" />
            </b-col>

            <b-col cols="12" v-if="filteredItems && filteredItems.length">
              <b-table
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="filteredItems"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(propertyId)="data">
                  <div style="display: flex; align-items: center;">
                    <img
                      v-if="!data.item.isFeatured"
                      src="@/assets/images/svg/star_empty.svg"
                      alt="empty_star"
                      @click="StarFillup(data.item, true)"
                      style="cursor:pointer;"
                    />
                    <img
                      v-else
                      src="@/assets/images/svg/star_filled.svg"
                      alt="filled_star"
                      @click="StarFillup(data.item, false)"
                      style="cursor:pointer;"
                    />
                    <a
                      :to="`property/${data.item.propertyId}`"
                      style="text-decoration: underline; margin-left: 10px"
                      @click="goToListingDetailPage(data.item)"
                    >
                      {{ data.item.propertyId }}
                    </a>
                  </div>
                </template>

                <template #cell(property)="data">
                  <div @click="callPropertyEmit(data.item)">
                    <router-link target="_blank" :to="`listing-management/${data.item.id}`">
                    <div style="display: flex; align-items: center">
                    <img
                      v-if="data.item.photos && data.item.photos.length"
                      width="40px"
                      height="40px"
                      style="
                        object-fit: cover;
                        border-radius: 5px;
                        margin-right: 15px;
                        min-width: 40px;
                        cursor:pointer;
                      "
                      :src="data.item.photos[0]"
                      alt="property"
                    />
                    <div>
                      <span
                        style="
                          white-space: normal;
                          display: block;
                          color: #17D1C6;
                          font-weight: 500;
                          cursor:pointer;
                        "
                        >{{ data.item.PropertyLocation }}</span
                      >
                      <!-- <span class="askingPriceText">$ {{ data.item.askingPrice }}</span> -->
                    </div>
                    </div>
                    </router-link>
                  </div>
                </template>

                <template #cell(property_type)="data">
                  <div style="white-space: nowrap">
                    {{ displayFullName(data.item.listingType) }}
                  </div>
                </template>

                <template #cell(listed_by)="data">
                  <div style="white-space: nowrap">
                    {{ data.item.listedBy || "N/A" }}
                  </div>
                </template>

                <template #cell(status)="data">
                  <div style="text-transform:capitalize;" :class="propertyStatus[data.item.status - 2].displayName" v-if="data.item.isArchived == false">
                    <!-- <p><span class="status-area">{{ propertyStatus[data.item.status - 2].displayName }}</span></p> -->
                    <!-- <b-dropdown
                      style="width: -webkit-fill-available; margin-bottom: 5px;"
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="filteredStatuses.filter((x) => x.key === data.item.status)[0].label"
                      :variant="filteredStatuses.filter((x) => x.key === data.item.status)[0].variant || 'dark'"
                    >
                      <b-dropdown-item v-for="(data2, index) in filteredStatuses" :key="index" variant="data2.variant" @click.prevent="data.item.status = data2.key">{{data2.label}}</b-dropdown-item>
                    </b-dropdown> -->
                    <!-- <span style="white-space: nowrap;">Auto-renews On 10/20/2022</span> -->
                    <b-dropdown
                      text-class="text-capital"
                      style="text-transform:capitalize;"
                      :text="propertyStatus[data.item.status-2].displayName.charAt(0).toUpperCase() + propertyStatus[data.item.status-2].displayName.slice(1)"
                      :class="[propertyStatus[data.item.status - 2].displayName,'archiveProp']"
                    >
                    <b-dropdown-item v-for="(statusP) in propertyStatus.filter((e)=>{return e.displayName !== 'pending'})" v-b-tooltip.hover="{ placement: 'left', title: getHoverText(statusP.key,statusP.displayName) }" style="text-transform:capitalize;" :key="statusP.key" @click="openStatusModel(statusP.key, data.item)">{{statusP.displayName}}</b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div v-else>
                    Archived
                  </div>
                </template>

                <template #cell(action)="data">
                  <div style="display: flex" class="actionImage">
                    <img
                      src="@/assets/images/svg/eye.svg"
                      alt="empty_star"
                      style="cursor:pointer;"
                      @click="goToListingDetailPage(data.item)"
                    />
                    <img 
                      v-if="data.item.isArchived == false"
                      src="@/assets/images/svg/edit.svg" 
                      alt="edit user"
                      style="cursor:pointer;"
                      @click="goToPropertyEditPage(data.item)"
                    />
                    <img 
                      :title="'Archive Property'"
                      v-if="data.item.isArchived == false && data.item.status == 5"
                      src="@/assets/images/svg/archive_icon.svg"  
                      alt="Archive Property"
                      style="cursor:pointer;"
                      @click="selectedArchiveProperty = data.item,$bvModal.show(`modal-trashDoc`)"
                    />
                    <!-- <img src="@/assets/images/svg/delete.svg" alt="empty_star"/> -->
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col v-else cols="12" style="width: 100%; text-align: center">
              <p>No records found</p>
            </b-col>
            <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol" v-if="filteredItems && filteredItems.length > 0">
              <!-- page length -->
               <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ totalRows }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-property-status"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="checkForModalOpen"
      @cancel="cancelStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>{{statusChangeModalText}}</h3>
    </b-modal>
    <b-modal
      id="modal-warning-status"
      ok-only
      centered
      hide-footer
    >
      <h3>You can't change property staus because there is no subscription for this property</h3>
    </b-modal>
    <b-modal
      id="modal-cancle-status"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatus"
      @cancel="cancelChangeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Changing the status to CANCELLED OR FULL OR DRAFT will require company contact with the user, to request the paid subscription again. Are you sure you want to change this property status?</h3>
    </b-modal>
    <b-modal
          id="modal-trashDoc"
          ok-title="Confirm"
          cancel-title="Cancel"
          centered
          @ok="updateArchiveStatus"
          @cancel="cancelTrashProp"
          :no-close-on-backdrop="true"
          hide-header
      >
      <h4 style="text-align:center;">Are you sure you want to archive this property? You will not be able to revert it back once the property is archived.</h4>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { getCollectionData } from "@/utils/FirebaseQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollections";
import { mapGetters,mapActions, mapMutations } from "vuex";
import { loopFunction } from "@/utils/commonMethods";
import {getCommaSeperatedNumber } from "@/utils/commonMethods";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as updateTypesense from "@/utils/TypeSenseQueries/updateQueries.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import axios from "axios";
import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    CardStatisticProfitChart,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: true,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "propertyId",
          label: "Property ID",
        },
        "property",
        "property_type",
        "listed_by",
        "listing_date",
        {
          key: "offersCount",
          label: "Application Count",
        },
        {
          key: "status",
          label: "Status",
        },
        "action",
      ],
      items: [],
      users: [],

      location: "",
      priceRange: "Select",
      bed: "Any",
      status: "Select",
      priceRangeOptions: ["Select",'0-100','101-1000','1000+'],
      bedOptions: ["Any",1,2,3,4,'5+'],
      statusChangeModalText: '',
      selectedProperty: {},
      selectedArchiveProperty: {},
      isCanclleStatus: false,
      isSecondModal: false
    };
  },

  created() {
    var self = this;
    // self.init();
    self.userData().then(() => {
      self.allPropertyData().then(() => {
        self.showSpinner = false;
      })
    })
  },

  methods: {
    ...mapActions({
        allPropertyData:"property/allPropertyData",
        userData:"user/userData"
    }),
    ...mapMutations({
        updateAllPropertyArray:"property/updateAllPropertyArray"
    }),
    getHoverText(key,statusType) {
      if(key == 2) {
        return '“Active ($)” is the only status in which you are being charged so people can find your open bed.';
      } else if(key == 3) {
        return '“Draft” means you need to move the listing to active and check out if you want to show your shelter location online. The draft is retained for 7 days.'
      } else if (key == 4) {
        return '“Full” means you are at capacity and the shelter location is showing externally in the Full area of Shelter-Registry.com at no charge.'
      } else if(key == 5) {
        return '“Cancelled” means the shelter location is no longer viable.'
      } else {
        return statusType
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    StarFillup(data, value) {
      var self = this;
      let index = self.filteredItems.findIndex((item) => {
        return item.id == data.id
      }) 
      if(index != -1){
        self.filteredItems[index].isFeatured = value;
      }
      if (value == true) {
        self.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Property added in featured list",
            variant: "success",
            icon: "",
          },
        });
      } else {
        self.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Property remove from featured list",
            variant: "success",
            icon: "",
          },
        });
      }
      // self.showSpinner = true;
      let updateObject = {
        isFeatured: value,
        updatedAt: new Date(),
      };
      let typeSenceObject = {
        isFeatured: value,
        updatedAt: parseInt(new Date().getTime() / 1000),
      };
      updateFirebase.updateRootCollectionDataByDocId(
        dbCollections.PROPERTY,
        data.id,
        updateObject,
        (res) => {
          updateTypesense
            .updatePropertyCollection(data.id, typeSenceObject)
            .then(() => {
              console.log("UPDATED IN TYPESENSE");
            })
            .catch((error) => {
              console.error("ERROR IN UPDATED IN TYPESENSE", error);
            });
          // self.showSpinner = false;
        }
      );
    },
    goToListingDetailPage(data) {
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
      self.$router.push({
        name: "listing-management-detail",
        params: { id: data.id, name: `${data.state},${data.country}` },
      });
    },
    callPropertyEmit(data){
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
    },
    goToPropertyEditPage(data){
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
      self.$router.push({
        name: "listing-management-edit",
        params: { id: data.id, name: `${data.state},${data.country}` },
        query:{name:'myList'}
      });
    },
    displayFullName(name){
      if(name == 'Mental Or Detox'){
        return 'Mental Or Detox';
      }else{
        return name;
      }
    },
    openStatusModel(key, item){
      try {
        this.selectedProperty = {
          key: key,
          item: item
        }
        if(this.selectedProperty.key !== this.selectedProperty.item.status) {
          // this.$bvModal.show(`modal-property-status`);
          this.statusChangeModalText = 'Are you sure you want to change this property status?';
          if(this.selectedProperty.item.status == 2 || this.selectedProperty.item.status == 6) {
              if(this.selectedProperty.key === 2 || this.selectedProperty.key == 6) {
                  // this.statusChangeModalText = 'Are you sure you want to change this property status?';
                  this.$bvModal.show(`modal-property-status`);
              }else if(this.selectedProperty.key == 4) {
                  this.isCanclleStatus = true;
                  this.isSecondModal = true;
                  // this.statusChangeModalText = 'Are you sure you want to change this property status?';
                  this.$bvModal.show(`modal-property-status`);
              } else if(this.selectedProperty.key == 5 || this.selectedProperty.key === 3) {
                  this.isCanclleStatus = true;
                  this.isSecondModal = true;
                  // this.statusChangeModalText = 'Are you sure you want to change this property status? ';
                  this.$bvModal.show(`modal-property-status`);
              }
          } 
          // else if(this.selectedProperty.item.status == 3) {
          //     if(this.selectedProperty.key === 2 || this.selectedProperty.key ===  6) {
          //         if(this.selectedProperty.item.subscriptionStatus !== 1){
          //           //warning can't change status
          //           this.$bvModal.show('modal-warning-status');
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     }else if(this.selectedProperty.key == 4 ) {
          //         this.isSecondModal = true;
          //         if(this.selectedProperty.item.subscriptionStatus === 1){
          //             this.isCanclleStatus = true;
          //             // this.statusChangeModalText = 'Are you sure you want to change this property staus to sold? It will cancle the subscription of the property';
          //             this.$bvModal.show(`modal-property-status`);
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     } else if(this.selectedProperty.key == 5) {
          //         this.isSecondModal = true;
          //         if(this.selectedProperty.item.subscriptionStatus === 1){
          //             this.isCanclleStatus = true;
          //             // this.statusChangeModalText = 'Are you sure you want to change this property staus to canclled? It will cancle the subscription of the property';
          //             this.$bvModal.show(`modal-property-status`);
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     }
          // }  
          else if(this.selectedProperty.item.status == 5 || this.selectedProperty.item.status == 4 || this.selectedProperty.item.status == 3) {
                if(this.selectedProperty.key === 2 || this.selectedProperty.key === 6) {
                   //warning can't change status
                   this.$bvModal.show('modal-warning-status');
                } else if(this.selectedProperty.key == 3) {
                    // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
                    this.$bvModal.show(`modal-property-status`);
                } else if(this.selectedProperty.key == 4) { 
                    this.isSecondModal = true;
                    this.$bvModal.show(`modal-property-status`);
                }   else if(this.selectedProperty.key == 5) {
                    this.isSecondModal = true;
                    this.$bvModal.show(`modal-property-status`);
                }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    checkForModalOpen() {
        if(this.isSecondModal) {
            this.$bvModal.show('modal-cancle-status');
        } else {
            this.changeStatus();
        }
    },
    cancelStatus() {
      this.$bvModal.hide('modal-property-status');
      this.selectedProperty = {};
      this.statusChangeModalText = '';
      this.isCanclleStatus = false;
    },
    cancelChangeStatus(){
      this.isSecondModal = false;
      this.isCanclleStatus = false;
      this.selectedProperty = {};
      this.$bvModal.hide('modal-cancle-status');
    },
    changeStatus() {
      var self = this;
      self.showSpinner = true;
      if(self.isCanclleStatus) {
          self.cancleSubscripiton();
      } else {
          self.directStatusChange();
      }
    },
    cancleSubscripiton() {
        try {
            axios.post(process.env.VUE_APP_APIURL + '/api/v1/delete-subcription', {
                subcriptionId : this.selectedProperty.item.subscription,
                statusKey: this.selectedProperty.key
            })
            .then((deleteRes) =>{
                if(deleteRes.data.status) {
                  this.updateAllPropertyArray({
                    id: this.selectedProperty.item.id,
                    subscription: "",
                    subscriptionStatus: 3
                  });
                  this.directStatusChange();
                } else {
                  console.error(deleteRes.data);
                  this.showSpinner = false;
                }
            })
            .catch((error) => {
                console.error(error);
                this.showSpinner = false;
            })
        } catch (error) {
            console.error(error);
            this.showSpinner = false;
        }
    },
    cancelTrashProp(){
        this.selectedArchiveProperty = ''
        this.$bvModal.hide(`modal-trashDoc`);
    },
    updateArchiveStatus() {
      try {
        this.isSpinner = true;
        let statusObject = {
          isArchived: true
        }
        db.collection(dbCollections.PROPERTY).doc(this.selectedArchiveProperty.id).update(statusObject).then(()=>{
          updateTypesense.updatePropertyCollection(this.selectedArchiveProperty.id, statusObject).then(() => {
            console.log(this.selectedArchiveProperty.id,'selectedArchiveProperty',statusObject);
            this.updateAllPropertyArray({
              id: this.selectedArchiveProperty.id,
              isArchived: true
            });
            this.cancelTrashProp()
            this.isSpinner = false;
          }).catch((error)=>{
            this.isSpinner = false;
            console.error(error)
          })
        }).catch((error)=>{
          this.isSpinner = false;
          console.error(error)
        })
      } catch (error) {
        console.error(error);
      }
    },
    directStatusChange() {
      try {
          let updateObj = {
              status: this.selectedProperty.key,
              subscriptionStatus: this.selectedProperty.key == 3 ? 4 : this.selectedProperty.key == 4 ? 2 : 3,
              updatedAt: new Date()
          }
          if(this.selectedProperty.key === 4 || this.selectedProperty.key === 5 || this.selectedProperty.key === 3) {
            updateObj.removeDocTime = new Date();
          }
          db.collection(dbCollections.PROPERTY)
            .doc(this.selectedProperty.item.id)
            .update(updateObj)
            .then(() => {
                updateTypesense.updatePropertyCollection(this.selectedProperty.item.id, {status: parseInt(this.selectedProperty.key)}).then(() => {
                    console.log("UPDATED IN TYPESENSE");
                })
                .catch((error) =>{
                    console.error(error);
                })
                this.showSpinner = false;
                this.updateAllPropertyArray({
                  id: this.selectedProperty.item.id,
                  status: this.selectedProperty.key
                });
                this.selectedProperty = {};
                this.statusChangeModalText = '';
                this.isCanclleStatus = false;
                if(this.isSecondModal) {
                  this.isSecondModal = false;
                }
                this.$bvModal.hide('modal-property-status');
                this.$toast.success("Status updated successfully");
            })
      } catch (error) {
          console.error(error);
          this.showSpinner = false;
      }
    }

  },

  computed: {
    ...mapGetters({ getStatuses: "admin/statusGetter",
                    getAllPropertyArray:"property/getAllProperty",
                    allUserDataArray:"user/allUserData"
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    filteredItems() {
      // debugger; // eslint-disable-line
      var self = this;
      let arr = [];
       if(self.getAllPropertyArray && self.getAllPropertyArray.length > 0 && self.allUserDataArray && self.allUserDataArray.length > 0){
          try{
            self.getAllPropertyArray.forEach(data => {
              if(data.userId && data.status !== 1){
                let index = self.allUserDataArray.findIndex(item => {
                  return item.id === data.userId
                })
                if(index != -1){
                  // if (data.listingType === "free_or_public") {
                  //   arr.push({
                  //     id: data.id,
                  //     subscription: data.subscription,
                  //     subscriptionStatus: data.subscriptionStatus,
                  //     listing_date: data.createdAt
                  //       ? moment(data.createdAt.seconds * 1000).format(
                  //           "MM/DD/YYYY"
                  //         )
                  //       : "",
                  //     propertyId: data.propertyId,
                  //     listingType: data
                  //       .listingType.split("_")
                  //       .map((x) => x.charAt(0).toUpperCase() + x.substring(1))
                  //       .join(" "),
                  //     offersCount: data.offersCount,
                  //     status: data.status,
                  //     country: data.contractBCountry,
                  //     state: data.contractBState,
                  //     askingPrice: getCommaSeperatedNumber(data.contractDAskingPrice),
                  //     askingPriceData: data.contractDAskingPrice,
                  //     photos: data.contractFUploadPhotos,
                  //     isFeatured: data.isFeatured,
                  //     beds:data.contractBBedroomCount ? data.contractBBedroomCount : 0,
                  //     listedBy: `${
                  //       self.allUserDataArray[index].firstName ? self.allUserDataArray[index].firstName  : ''
                         
                  //     } ${
                  //       self.allUserDataArray[index].lastName ? self.allUserDataArray[index].lastName : ''
                  //     }`,
                  //     PropertyLocation: `${
                  //       data.contractBStreetNumber
                  //         ? data.contractBStreetNumber
                  //         : ""
                  //     } ${
                  //       data.contractBStreetName
                  //         ? data.contractBStreetName + ","
                  //         : ""
                  //     } ${
                  //       data.contractBUnitorLot
                  //         ? data.contractBUnitorLot + ", "
                  //         : ""
                  //     }${data.contractBCity}, ${
                  //       data.contractBState
                  //     }, ${data.contractBCountry}, ${
                  //       data.contractBZipCode
                  //     }`,
                  //   });
                  // }else 
                  if (data.listingType === "half_way_or_shared" || data.listingType == 'mental_or_detox_facility' || data.listingType == 'free_or_public_shelter') {
                    arr.push({
                      id: data.id,
                      subscription: data.subscription,
                      subscriptionStatus: data.subscriptionStatus,
                      listing_date: data.createdAt
                        ? moment(data.createdAt.seconds * 1000).format(
                            "MM/DD/YYYY"
                          )
                        : "",
                      propertyId: data.propertyId,
                      listingType: data.listingType === 'half_way_or_shared'
                                ? 'Half-Way or Shared House'
                                : data.listingType === 'mental_or_detox_facility'
                                ? 'Mental or Detox Facility'
                                : data.listingType === 'free_or_public_shelter'
                                ? 'Free or Public Shelter'
                                : '',
                      offersCount: data.offersCount,
                      status: data.status,
                      country: data.leaseBCountry,
                      state: data.leaseBState,
                      askingPrice: getCommaSeperatedNumber(data.leaseDAskingPrice),
                      askingPriceData: data.leaseDAskingPrice,
                      photos: data.leaseFUploadPhotos,
                      isFeatured: data.isFeatured,
                      listedBy: `${
                        self.allUserDataArray[index].firstName ? self.allUserDataArray[index].firstName  : ''
                         
                      } ${
                        self.allUserDataArray[index].lastName ? self.allUserDataArray[index].lastName : ''
                      }`,
                      beds:data.leaseBBedroomCount ? data.leaseBBedroomCount : 0,
                      PropertyLocation: `${
                        data.leaseAStreetNumber
                          ? data.leaseAStreetNumber
                          : ""
                      } ${
                        data.leaseAStreetName
                          ? data.leaseAStreetName + ","
                          : ""
                      } ${
                        data.leaseAUnitorLot
                          ? data.leaseAUnitorLot + ","
                          : ""
                      } ${data.leaseBCity}, ${
                        data.leaseBState
                      }, ${data.leaseBCountry}, ${
                        data.leaseBZipCode
                      }`,
                      isArchived : data.isArchived ? data.isArchived : false
                    });
                  }
                  // else if (data.listingType === "mental_or_detox") {
                  //   arr.push({
                  //     id: data.id,
                  //     subscription: data.subscription,
                  //     subscriptionStatus: data.subscriptionStatus,
                  //     listing_date: data.createdAt
                  //       ? moment(data.createdAt.seconds * 1000).format(
                  //           "MM/DD/YYYY"
                  //         )
                  //       : "",
                  //     propertyId: data.propertyId,
                  //     listingType: data
                  //       .listingType.split("_")
                  //       .map((x) => x.charAt(0).toUpperCase() + x.substring(1))
                  //       .join(" "),
                  //     offersCount: data.offersCount,
                  //     status: data.status,
                  //     country: data.ownerBCountry,
                  //     state: data.ownerBState,
                  //     askingPrice:getCommaSeperatedNumber(data.ownerDAskingPrice),
                  //     askingPriceData: data.ownerDAskingPrice,
                  //     photos: data.ownerFUploadPhotos,
                  //     isFeatured: data.isFeatured,
                  //     listedBy: `${
                  //       self.allUserDataArray[index].firstName ? self.allUserDataArray[index].firstName  : ''
                  //     } ${
                  //       self.allUserDataArray[index].lastName ? self.allUserDataArray[index].lastName : ''
                  //     }`,
                  //     beds:data.ownerBBedroomCount ? data.ownerBBedroomCount : 0,
                  //     PropertyLocation: `${
                  //       data.ownerBStreetNumber
                  //         ? data.ownerBStreetNumber
                  //         : ""
                  //     } ${
                  //       data.ownerBStreetName
                  //         ? data.ownerBStreetName + ","
                  //         : ""
                  //     } ${
                  //       data.ownerBUnitorLot
                  //         ? data.ownerBUnitorLot + ", "
                  //         : "" 
                  //     }${data.ownerBCity}, ${
                  //       data.ownerBState
                  //     }, ${data.ownerBCountry}, ${
                  //       data.ownerBZipCode
                  //     }`,
                  //   });
                  // }
                }
              }
            })
            let tmp = arr;
              if (self.location) {
                tmp = tmp.filter((f) =>
                  `${f.PropertyLocation.toLowerCase()}`.includes(
                    self.location.toLowerCase()
                  )
                );
              }
              if (self.status && self.status != "Select") {
                tmp = tmp.filter(
                  (f) => self.status === self.getStatuses[f.status - 1].displayName
                );
              }
              if (self.bed && self.bed != 'Any') {
                if(self.bed != '5+'){
                  tmp = tmp.filter(
                    (f) => Number(f.beds) == self.bed
                  );
                }else{
                  tmp = tmp.filter(
                    (f) => Number(f.beds) >= 5
                  );
                }
              }
              if(self.priceRange && self.priceRange != 'Select'){
                console.log(tmp)
                if(self.priceRange == '0-100'){
                  tmp = tmp.filter(
                    (f) => (Number(f.askingPrice) >= 0 && Number(f.askingPrice) <= 100 )
                  );
                }else if(self.priceRange == '101-1000'){
                  tmp = tmp.filter(
                    (f) => (Number(f.askingPrice) >= 101 && Number(f.askingPrice) <= 1000 )
                  );
                }else{
                  console.log(self.priceRange)
                  tmp = tmp.filter(
                    (f) => Number(f.askingPriceData) > 1000
                  );
                }
              }
              self.totalRows = tmp.length;

              return tmp;
          }catch(error){
            console.log('ERROR in get property data',error);
          }
        }else{
          self.showSpinner = false;
        }
    },
    filteredStatuses() {
      var self = this;
      // console.log('self.usersStatus',self.usersStatus);
      let arr = [];
      if (self.getStatuses.length) {
        arr.push("Select");
        self.getStatuses.forEach((data) => {
          if(data.key !== 1) {
            arr.push(data.displayName);
          }
        });
        return arr;
      } else {
        return [];
      }
    },
    propertyStatus() {
      let status = [...this.getStatuses]
      let draftInd = status.findIndex((data) => {
        return data.displayName == "draft"
      })
      if (draftInd>-1) {
        status.splice(draftInd,1)
        return status
      } else {
        return status
      }
    },
  },
};
</script>

<style>
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
@media(max-width:1850px){
.listingManagementWrapper .col-sm-4.col-md-2 {
    max-width: 19%;
    width: 19%;
    flex: 0 0 19%;
}
}

</style>